import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "app-root"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_loading = _resolveComponent("van-loading");
  var _component_router_view = _resolveComponent("router-view");
  var _component_easy_ring = _resolveComponent("easy-ring");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_van_loading, {
    class: "overall_loading",
    color: "white"
  }, null, 512), [[_vShow, _ctx.loading]]), _createVNode(_component_router_view), _createVNode(_component_easy_ring, {
    open: _ctx.openNotify,
    ring: _ctx.ring,
    "onUpdate:ring": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.ring = $event;
    }),
    src: _ctx.notifyAudio
  }, null, 8, ["open", "ring", "src"]), _withDirectives(_createVNode(_component_van_loading, {
    class: "overall_loading",
    color: "white"
  }, null, 512), [[_vShow, _ctx.loading]])]);
}